import React, { useEffect } from 'react';
import Seo from '@/components/SEO';

const Politica = () => {
  const loadNotices = () => {
    const { OneTrust } = window;

    if (!OneTrust) {
      return;
    }

    OneTrust.NoticeApi.Initialized.then(() => {
      OneTrust.NoticeApi.LoadNotices([
        'https://privacyportal-br-cdn.onetrust.com/7df92ceb-936a-459d-a84f-9b137870df61/privacy-notices/386a1c47-da6e-4eab-9424-ae55388dd70a.json',
      ]);
    });
  };

  useEffect(() => loadNotices(), []);

  return (
    <>
      <Seo
        title="Politica de privacidade"
        description="Conheça a política de privacidade da Simples Dental. Saiba mais sobre como protegemos suas informações pessoais e garantimos sua segurança ao utilizar nossos serviços."
      />
      <div id="otnotice-386a1c47-da6e-4eab-9424-ae55388dd70a" className="otnotice"></div>
    </>
  );
};

export default Politica;
