import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        keywords
        author
        robots
        locale
        siteName
        googleSiteVerification
        twitterUsername
      }
    }
  }
`;

export interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  schema?: { [key: string]: any };
}

const SEO = ({ title, description, image, schema }: SEOProps) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
    keywords,
    author,
    robots,
    locale,
    siteName,
    googleSiteVerification,
  } = site.siteMetadata;

  const fullUrl = `${siteUrl}${pathname}`;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: fullUrl,
    keywords,
    author,
    robots,
    locale,
    siteName,
    googleSiteVerification,
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={title ? titleTemplate : undefined}
      htmlAttributes={{
        lang: 'pt-br',
      }}
    >
      <meta name="google-site-verification" content={seo.googleSiteVerification} />
      <meta name="author" content={seo.author} />
      <meta name="robots" content={seo.robots} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="keywords" content={seo.keywords} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={seo.siteName} />
      <meta property="og:locale" content={seo.locale} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:site" content={twitterUsername} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta itemProp="name" content={seo.siteName} />
      <meta itemProp="description" content={seo.description} />
      <meta itemProp="image" content={seo.image} />

      {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
    </Helmet>
  );
};

export default SEO;
